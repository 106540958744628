import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { ProspectArguments } from "../../types/Prospects";
import isNil from "lodash.isnil";
const baseUrl = "/api/persons";

export const getPaymentMovements = (personaId: number) => {
  return {
    type: actionTypes.GET_PAYMENT_MOVEMENTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/movements/${personaId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getResponsables = (personaId: number) => {
  return {
    type: actionTypes.GET_RESPONSABLES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/${personaId}/responsables`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPersonsRequest = (args: ProspectArguments) => {
  const params = { ...args };

  return axios.get(`${constants.BASE_URL}${baseUrl}`, {
    withCredentials: true,
    params
  });
};

export const getPersons = (args: ProspectArguments): IAction => {
  return {
    type: actionTypes.GET_PERSONS,
    payload: {
      promise: getPersonsRequest(args)
    }
  };
};

export const getPersonById = (personaId: string): IAction => {
  return {
    type: actionTypes.GET_PERSON_BY_ID,
    payload: {
      promise: axios.get(`${constants.BASE_URL}/api/persons/${personaId}`, {
        withCredentials: true
      })
    }
  };
};

export const getPersonByNumeroEmpleado = (numeroEmpleado: string): IAction => {
  return {
    type: actionTypes.GET_PERSON_BY_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/persons/empleados/${numeroEmpleado}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPersonByIdRequest = (personaId: string) => {
  return axios.get(`${constants.BASE_URL}/api/persons/${personaId}`, {
    withCredentials: true
  });
};

export const updatePhoto = (idPersona: string, photo: Blob) => {
  const data = new FormData();
  data.append("fotoContenido", photo);
  return {
    type: actionTypes.PERSON_UPDATE_PHOTO,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/persons/${idPersona}/photo`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updatePhotoProm = (idPersona: string, photo: Blob) => {
  const data = new FormData();
  data.append("fotoContenido", photo);
  return axios.put(
    `${constants.BASE_URL}/api/persons/${idPersona}/photo`,
    data,
    {
      withCredentials: true
    }
  );
};

export const deleteEmail = (addressId: string): IAction => {
  return {
    type: actionTypes.DELETE_PERSON_EMAIL,
    payload: {
      promise: axios.delete(
        `${constants.BASE_URL}/api/persons/emails/${addressId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateEmail = (personId: string, body: object): IAction => {
  return {
    type: actionTypes.UPDATE_PERSON_EMAIL,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/persons/emails/person/${personId}`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deleteAddress = (addressId: string): IAction => {
  return {
    type: actionTypes.DELETE_PERSON_ADDRESS,
    payload: {
      promise: axios.delete(
        `${constants.BASE_URL}/api/persons/address/${addressId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateAddress = (personId: string, body: object): IAction => {
  body["domicilio"] = body["domicilio"] || "DOMICILIO";
  return {
    type: actionTypes.UPDATE_PERSON_ADDRESS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/persons/address/person/${personId}`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deleteContact = (contactId: string): IAction => {
  return {
    type: actionTypes.DELETE_PERSON_CONTACT,
    payload: {
      promise: axios.delete(
        `${constants.BASE_URL}/api/persons/contacts/${contactId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateContact = (personId: string, body: object): IAction => {
  return {
    type: actionTypes.UPDATE_PERSON_CONTACT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/persons/contacts/person/${personId}`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deletePhone = (phoneId: string): IAction => {
  return {
    type: actionTypes.DELETE_PERSON_PHONE,
    payload: {
      promise: axios.delete(
        `${constants.BASE_URL}/api/persons/phones/${phoneId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updatePhone = (personId: string, body: object): IAction => {
  return {
    type: actionTypes.UPDATE_PERSON_PHONE,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/persons/phones/person/${personId}`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveResponsable = (body: object): IAction => {
  return {
    type: actionTypes.SAVE_RESPONSABLE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/persons/responsable`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const toggleResponsable = (
  idPersonaMenor: string,
  idPersonaResponsable: string
): IAction => {
  return {
    type: actionTypes.UPDATE_RESPONSABLE,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/persons/responsable`,
        {},
        {
          withCredentials: true,
          params: {
            idPersonaMenor,
            idPersonaResponsable
          }
        }
      )
    }
  };
};

export const createPerson = (body: object): IAction => {
  return {
    type: actionTypes.CREATE_PERSON,
    payload: {
      promise: axios.post(`${constants.BASE_URL}/api/persons`, body, {
        withCredentials: true
      })
    }
  };
};

export const setSelectedPerson = (person: object): IAction => {
  return {
    type: actionTypes.SET_SELECTED_PERSON,
    payload: person
  };
};

export const clearSelectedPerson = (): IAction => {
  return {
    type: actionTypes.CLEAR_SELECTED_PERSON,
    payload: null
  };
};

export const savePersonClubIngress = (body: object) => {
  return axios.post(`${constants.BASE_URL}/api/persons/ingress`, body, {
    withCredentials: true
  });
};

export const getDealers = (
  name: string,
  page: string,
  size: string
): IAction => {
  let sendName = null;
  if (!isNil(name) && name.toString().length != 0) {
    sendName = name;
  }
  return {
    type: actionTypes.GET_DEALERS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/persons/concesionarios/${sendName}/${page}/${size}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const addDealers = (body: object): IAction => {
  return {
    type: actionTypes.ADD_DEALERS,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/persons/concesionarios/add`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getClubDealer = (personaId: string): IAction => {
  return {
    type: actionTypes.GET_CLUB_DEALER,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/persons/persona-club/${personaId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateClubDealer = (personId: string, body: object): IAction => {
  return {
    type: actionTypes.UPDATE_CLUB_DEALER,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/persons/persona-club/${personId}`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getVariablesChargeDealers = (
  clubId: string,
  year: string,
  month: string
): IAction => {
  return {
    type: actionTypes.GET_VARIABLES_CHARGE_DEALERS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/maintenances/concesionario/cargo/variable/${clubId}/${year}/${month}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const generateVariableCharge = (
  configuracionCargoId: string,
  monto: string
): IAction => {
  return {
    type: actionTypes.UPDATE_CLUB_DEALER,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/maintenances/concesionario/cargo/variable/${configuracionCargoId}/${monto}`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const addDealerConfigCharge = (body: object): IAction => {
  return {
    type: actionTypes.ADD_DEALER_CONFIG_CHARGE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/persons/concesionario/configuracion/cargo`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateDealerConfigCharge = (body: object): IAction => {
  return {
    type: actionTypes.UPDATE_DEALER_CONFIG_CHARGE,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/persons/concesionario/configuracion/cargo`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getCorporative = (name: string, page: string, size: string) => {
  let sendName = null;
  if (!isNil(name) && name.toString().length != 0) {
    sendName = name;
  }
  return axios.get(
    `${constants.BASE_URL}/api/persons/corporativos/${sendName}/${page}/${size}`,
    {
      withCredentials: true
    }
  );
};

export const addCorporative = (body: object) => {
  return axios.post(
    `${constants.BASE_URL}/api/persons/corporativos/add`,
    body,
    {
      withCredentials: true
    }
  );
};

export const deletePersonRequest = (personId: string) => {
  return axios.delete(`${constants.BASE_URL}/api/persons/${personId}`, {
    withCredentials: true
  });
};

export const getValidarCurp = (idPersona: any, curp: any) => {
  return axios.get(`${constants.BASE_URL}/api/persons/verificar-curp`, {
    withCredentials: true,
    params: {
      idPersona,
      curp
    }
  });
};
export const postValidarCorreo = async (idPersona: any, correo: any) => {
  return axios.post(
    `${constants.BASE_URL}/api/persons/verificar-correo`,
    {},
    {
      withCredentials: true,
      params: {
        idPersona,
        correo
      }
    }
  );
};
export const postValidarTelefono = async (idPersona: any, telefono: any) => {
  return axios.post(
    `${constants.BASE_URL}/api/persons/verificar-telefono`,
    {},
    {
      withCredentials: true,
      params: {
        idPersona,
        telefono
      }
    }
  );
};
export const postValidarCodigo = async (
  dato: any,
  codigo: any,
  idPersona?: any
) => {
  return axios.post(
    `${constants.BASE_URL}/api/persons/verificar-codigo`,
    {},
    {
      withCredentials: true,
      params: {
        codigo,
        dato,
        idPersona
      }
    }
  );
};
