import React, { Children, ReactElement } from "react";
import Reform from "@franleplant/reform";
import { Styled } from "../../decorators/Artifact";
import s from "./styles.scss";
import FormBuilderProps from "./FormBuilder.props";
import FormBuilderState from "./FormBuilder.state";
import { errorNotification } from "../../../utils/notifications";
import { isFunction } from "lodash";

@Styled(s)
export default class FormBuilder<
  INP extends string,
  BTN extends string
> extends React.Component<FormBuilderProps<INP, BTN>, FormBuilderState<INP>> {
  state = {
    fields: {} as { [key in INP]: any },
    init: null,
    errors: {},
    dirty: {} as { [key in INP]: boolean },
    devErrors: [],
    initLoaded: false,
    dirtied: false,
    triggerValueCounter: 0,
    partnersRelation: {} as { [key in INP]: INP[] }
  };

  /* Propiedades para la validación de formularios */
  reform = null;
  validationRules = {} as any;
  validationMessages = {
    maxDate: () => "Fecha mayor a la admitida",
    maxMonth: () => "Mes mayor al admitido",
    maxNumber: () => "Número mayor al admitido",
    maxTime: () => "Tiempo mayor al admitido",
    maxWeek: () => "Semana mayor a la admitida",
    minDate: () => "Fecha menor a la admitida",
    minMonth: () => "Mes menor al admitido",
    minNumber: () => "Número menor al admitido",
    minTime: () => "Tiempo menor al admitido",
    minWeek: () => "Semana menor a la admitida",
    month: () => "Mes inválido",
    number: () => "Número inválido",
    range: () => "Fuera de rango",
    time: () => "Valor de tiempo incorrecto",
    url: () => "Formato de Url incorrecto",
    week: () => "Semana incorrecta",
    color: () => "Formato de color incorrecto",
    date: () => "Formato de fecha incorrecto",
    email: () => "Correo inválido",
    minLength: num => `Debe tener al menos ${num} caracteres`,
    maxLength: num => `Deber tener maximo ${num} caracteres`,
    pattern: () => "Formato incorrecto",
    required: () => "Campo obligatorio",
    default: () => "Campo incorrecto"
  } as any;

  triggerValues: { [key in INP]: () => any } = {} as any;

  componentDidMount() {
    if (this.props.triggerSubmit) {
      this.props.triggerSubmit(this.triggerSubmit);
    }

    const emptyForm = {} as any;
    const partnersRelation = {} as { [key in INP]: INP[] };
    if (this.props.config.validations) {
      // Se recorren los campos
      Object.keys(this.props.config.validations).forEach(field => {
        const fieldValidations = this.props.config.validations[field];
        const rules = {} as any;
        this.validationRules[field] = rules;
        emptyForm[field] = null;

        // Se recorren las reglas
        Object.keys(fieldValidations).forEach(rule => {
          const validator = fieldValidations[rule].validator;
          const message = fieldValidations[rule].message;
          const partners = fieldValidations[rule].partners;

          if (validator && message) {
            // Se crean los objetos esperados de Reform
            const ruleName = `${field}_${rule}`;
            rules[ruleName] = (...opts) =>
              validator(this.state.fields[field], this.state.fields, ...opts);
            this.validationMessages[ruleName] = (...opts) =>
              message(this.state.fields[field], this.state.fields, ...opts);
          } else {
            rules[rule] = fieldValidations[rule];
          }

          if (partners) {
            partners.forEach(partner => {
              const current = partnersRelation[partner] || [];
              partnersRelation[partner] = [...new Set([...current, field])];
            });
          }
        });
      });
    }

    this.setState({ partnersRelation });

    if (this.props.init) {
      this.setState(
        {
          fields: {
            ...this.props.init
          },
          init: {
            ...this.props.init
          },
          initLoaded: true
        },
        () => this.triggerInit()
      );
    } else {
      this.setState(
        {
          fields: {
            ...emptyForm
          }
        },
        () => this.triggerInit()
      );
    }

    this.reform = Reform.reactMixins.objectMixin(this);
  }

  componentDidUpdate(prevProps: Readonly<FormBuilderProps<INP, BTN>>): void {
    if (prevProps.init != this.props.init && !this.state.initLoaded) {
      this.setState(
        {
          fields: {
            ...this.props.init
          },
          init: {
            ...this.props.init
          },
          initLoaded: true
        },
        () => this.triggerInit()
      );
    }
  }

  triggerSetter = (fieldName: string) => {
    if (!this.triggerValues[fieldName]) {
      return trigger => {
        this.triggerValues[fieldName] = trigger;

        this.setState({
          fields: {
            ...this.state.fields,
            [fieldName]: trigger()
          }
        });
      };
    }

    return this.triggerValues[fieldName];
  };

  triggerInit = () => {
    setTimeout(() => {
      const fields = {} as any;
      Object.keys(this.triggerValues).forEach(
        name => (fields[name] = this.triggerValues[name]())
      );
      this.setState({ fields });
    }, 2000);
  };

  setter = (fieldName: string, value: any) => {
    const fields = {
      ...this.state.fields,
      [fieldName]: value
    };

    if (this.props.onChange) {
      this.props.onChange(fields);
    }

    this.setState(
      {
        fields: fields,
        dirty: {
          ...this.state.dirty,
          [fieldName]: true
        }
      },
      () => {
        this.reform &&
          this.validationRules[fieldName] &&
          this.reform.validateField(fieldName, value);

        if (this.state.partnersRelation[fieldName]) {
          const partners = this.state.partnersRelation[fieldName];
          const dirtyPartner = {} as any;
          partners.forEach(partner => {
            dirtyPartner[partner] = true;
          });

          this.setState(
            {
              dirty: {
                ...this.state.dirty,
                ...dirtyPartner
              }
            },
            () => {
              partners.forEach(partner => {
                this.reform &&
                  this.validationRules[partner] &&
                  this.reform.validateField(
                    partner,
                    this.state.fields[partner]
                  );
              });
            }
          );
        }
      }
    );
  };

  submit = evt => {
    evt.preventDefault();
    const formInfo = this.triggerSubmit();

    // Se valida el formulario en busca de errores
    if (!formInfo.hasError) {
      this.props.submit && this.props.submit(formInfo.values);
    } else {
      errorNotification("Verifique la información solicitada");
    }
  };

  triggerSubmit = () => {
    // Se ensucian los campos para presentar los errores
    if (!this.state.dirtied) {
      const dirty = {} as any;
      Object.keys(this.props.config.validations || {}).forEach(
        name => (dirty[name] = true)
      );
      this.setState({
        dirty: {
          ...this.state.dirty,
          ...dirty
        },
        dirtied: true
      });
    }

    const hasError = !this.reform.validateFormFromState();
    return {
      hasError,
      values: this.state.fields
    };
  };

  render() {
    const formEls = Children.toArray(this.props.children) as ReactElement[];
    const shadow =
      this.props.lock || this.props.processing || this.props.loading;

    return (
      <form
        onSubmit={this.submit}
        autoComplete="off"
        className={`${s.formBuilder} ${shadow ? s.shadow : ""} mb-5`}
      >
        {!!this.state.devErrors && !!this.state.devErrors.length && (
          <div className="alert alert-danger">
            {this.state.devErrors.map((err, i) => (
              <p key={i}>{err}</p>
            ))}
          </div>
        )}
        {this.props.config.rows.map((names, i) => {
          return (
            <div key={"row-" + i} className="row mt-3 mb-3">
              {names.map(name => {
                // El componente es customizado
                const custom = formEls.find(
                  ele => ele.props["data-form-name"] == name
                );

                if (custom) {
                  return React.cloneElement(custom);
                }

                const inputs = this.props.config.inputs;
                const buttons = this.props.config.buttons;
                const input = inputs.find(input => input.name == name);
                const button = buttons.find(button => button.name == name);
                const formEl = formEls.find(fc => fc.props.name == name);
                const key = `${this.props["key"] || ""}_${name}`;
                const messages =
                  (this.validationRules[name] &&
                    this.state.fields[name as INP] !== undefined &&
                    this.reform.mapFieldErrors(name)) ||
                  [];
                const message = messages.length ? messages[0] : null;

                // Se esperan componentes de formulario
                if (input && (formEl || input.component)) {
                  const ctor = formEl ? formEl.type : input.component;
                  const oProps = {
                    ...(input.props || {}),
                    ...(formEl ? formEl.props : {})
                  };
                  const props = {
                    ...oProps,
                    disabled: isFunction(oProps.disabled)
                      ? oProps.disabled(this.state.fields)
                      : oProps.disabled,
                    hidden: isFunction(oProps.hidden)
                      ? oProps.hidden(this.state.fields)
                      : oProps.hidden,
                    name: name,
                    label: input.label,
                    inputClass: input.className,
                    init: this.state.init
                      ? this.state.init[input.name]
                      : (oProps && oProps.init) || null,
                    dirty: this.state.dirty[input.name],
                    triggerValue: this.triggerSetter(input.name),
                    formKey: this.props["key"] || "",
                    message,
                    status: message ? "ERR" : null,
                    setter: this.setter,
                    xCol: input.bts && input.bts.xCol,
                    sCol: input.bts && input.bts.sCol,
                    mCol: input.bts && input.bts.mCol,
                    lCol: input.bts && input.bts.lCol,
                    xOff: input.bts && input.bts.xOff,
                    sOff: input.bts && input.bts.sOff,
                    mOff: input.bts && input.bts.mOff,
                    lOff: input.bts && input.bts.lOff,
                    classCol: input.bts && input.bts.className
                  };

                  return this.renderBuilder(ctor, key, props);
                }

                // Se esperan botones
                if (button && (formEl || button.component)) {
                  const ctor = formEl ? formEl.type : button.component;
                  const oProps = {
                    ...(button.props || {}),
                    ...(formEl ? formEl.props : {})
                  };
                  const props = {
                    ...oProps,
                    name: button.name,
                    label:
                      button.isSubmit && this.props.loading
                        ? "Espere..."
                        : (formEl && formEl.props.label) || button.label,
                    disabled: isFunction(oProps.disabled)
                      ? oProps.disabled(this.state.fields)
                      : oProps.disabled,
                    hidden: isFunction(oProps.hidden)
                      ? oProps.hidden(this.state.fields)
                      : oProps.hidden,
                    addTopMargin: oProps.addTopMargin,
                    isSubmit: button.isSubmit || false,
                    buttonClass: button.isSubmit
                      ? `${s.primaryButton} ${button.className || ""}`
                      : `${s.secondaryButton} ${button.className || ""}`,
                    processing:
                      (button.isSubmit && this.props.processing) ||
                      (formEl && formEl.props.processing),
                    xCol: button.bts && button.bts.xCol,
                    sCol: button.bts && button.bts.sCol,
                    mCol: button.bts && button.bts.mCol,
                    lCol: button.bts && button.bts.lCol,
                    xOff: button.bts && button.bts.xOff,
                    sOff: button.bts && button.bts.sOff,
                    mOff: button.bts && button.bts.mOff,
                    lOff: button.bts && button.bts.lOff,
                    classCol: button.bts && button.bts.className
                  };

                  return this.renderBuilder(ctor, key, props);
                }

                return null;
              })}
            </div>
          );
        })}
      </form>
    );
  }

  renderBuilder = (Component: any, key: string, props: any) => {
    return <Component key={key} {...props} />;
  };
}
