import React from "react";
import ModalFrame from "../../../toolkit/modalFrame";
import AddPerson from "../../components/addPerson";
import { connect } from "react-redux";
import {
  getPersons,
  getPersonById,
  createPerson,
  setSelectedPerson,
  getPersonByNumeroEmpleado
} from "../../actions/persons";

interface Props {
  isOpen: boolean;
  toggle: () => any;
  searchPersons: (args: object) => any;
  getpersonByID: (id: string) => any;
  getPersonByNumeroEmpleado: (numeroEmpleado: string) => any;
  personByID: object;
  setSelectedPerson: (person: any) => any;
  createPerson: (person: object) => any;
  personCreated: boolean;
  personLinkError: any;
}

class AddPersonModal extends React.Component<Props> {
  componentDidUpdate({ personCreated: prevPersonCreated }) {
    if (!prevPersonCreated && this.props.personCreated) {
      this.props.toggle();
    }
  }

  render() {
    return (
      <ModalFrame
        title="Nueva Persona"
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size="lg"
      >
        <AddPerson
          searchClients={this.props.searchPersons}
          searchClientByID={this.props.getpersonByID}
          getPersonByNumeroEmpleado={this.props.getPersonByNumeroEmpleado}
          personByID={this.props.personByID}
          setSelectedPerson={this.props.setSelectedPerson}
          createPerson={this.props.createPerson}
          personLinkError={this.props.personLinkError}
        />
      </ModalFrame>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  searchPersons: (args: object) => {
    return dispatch(getPersons(args));
  },
  getpersonByID: (id: string) => {
    return dispatch(getPersonById(id));
  },
  getPersonByNumeroEmpleado: (numeroEmpleado: string) => {
    return dispatch(getPersonByNumeroEmpleado(numeroEmpleado));
  },
  createPerson: (person: object) => {
    dispatch(createPerson(person));
  },
  setSelectedPerson: (person: object) => {
    dispatch(setSelectedPerson(person));
  }
});

const mapStateToProps = state => {
  const { persons } = state;

  return {
    personByID: persons.personById,
    personTypes: persons.personTypes,
    personCreated: persons.personCreated,
    personLinkError: persons.personLinkError
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPersonModal);
