import React from "react";
import _ from "lodash.isequal";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import BaseInput from "../../../toolkit/baseInput";
import { Button, Table, FormGroup, Label, Input } from "reactstrap";
import s from "./styles.scss";
import {
  ProspectArguments,
  Prospect,
  PARTNER_TYPES,
  PEOPLE_TYPES
} from "../../../types/Prospects";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import isNil from "lodash.isnil";
import {
  errorNotification,
  NOT_PERSON_FINDED
} from "../../../utils/notifications";

interface Props {
  searchClients: (args: ProspectArguments) => any;
  searchClientByID: (id: string) => any;
  getPersonByNumeroEmpleado: (numeroEmpleado: string) => any;
  history: any;
  match: any;
  setSelectedPerson: (person: any) => any;
  createPerson: (person: object) => any;
  personLinkError: any[];
}

enum SCREEN_MODES {
  SEARCHING,
  CREATING
}

enum Sexos {
  MASCULINO = "MASCULINO",
  FEMENINO = "FEMENINO"
}
interface Person {
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
  fechaNacimiento: any;
  sexo: Sexos;
  entidadNacimiento: string;
  bloqueoMail: boolean;
  numeroEmpleado: number;
  bloqueoCallCenter: boolean;
  tipoCliente: PARTNER_TYPES;
  tipo: PARTNER_TYPES;
}

interface State {
  searchFields: {
    nombre: string;
    apellido: string;
    edad: any;
    sexo: Sexos;
    tipo: PEOPLE_TYPES;
  };
  errorMessage: boolean;
  personFields: Person;
  personaID: string;
  selectedPerson: string;
  screenMode: SCREEN_MODES;
  persons: Prospect[];
  personByID: Prospect;
  loading: boolean;
}

const genreOptions = [
  { value: "", label: "Seleccione" },
  { value: "FEMENINO", label: "Femenino" },
  { value: "MASCULINO", label: "Masculino" }
];

const entidadNacimientoOptions = [
  { value: "", label: "Ninguno" },
  { value: "NACIONAL", label: "Nacional" },
  { value: "EXTRANJERO", label: "Extranjero" }
];

const typeOptions = [
  {
    label: "Cliente",
    value: PEOPLE_TYPES.CLIENTE
  },
  {
    label: "Cliente integrante",
    value: PEOPLE_TYPES.CLIENTE_INTEGRANTE
  },
  {
    label: "Cliente titular",
    value: PEOPLE_TYPES.CLIENTE_TITULAR
  },
  {
    label: "Externo",
    value: PEOPLE_TYPES.EXTERNO
  },
  {
    label: "Prospecto",
    value: PEOPLE_TYPES.PROSPECTO
  },
  {
    label: "Corporativo",
    value: PEOPLE_TYPES.CORPORATIVO
  },
  {
    label: "Empleado",
    value: PEOPLE_TYPES.EMPLEADO
  }
];

class AddPerson extends React.Component<Props, State> {
  state = {
    searchFields: {
      nombre: "",
      apellido: "",
      edad: 0,
      sexo: "",
      tipo: PEOPLE_TYPES.CLIENTE
    },
    personFields: {
      nombre: null,
      primerApellido: null,
      segundoApellido: null,
      fechaNacimiento: new Date(),
      sexo: "",
      entidadNacimiento: "",
      bloqueoMail: false,
      bloqueoCallCenter: false,
      numeroEmpleado: null,
      tipoCliente: PARTNER_TYPES.EXTERNO,
      tipo: PARTNER_TYPES.EXTERNO
    },
    errorMessage: false,
    personaID: "",
    selectedPerson: null,
    screenMode: SCREEN_MODES.SEARCHING,
    personByID: null,
    persons: null,
    loading: false
  };

  onChangeSearchFieldsFactory = fieldName => {
    return event => {
      let value;
      if (fieldName === "edad") {
        value = event;
      } else {
        value = event.target.value;
      }
      this.setState(state => {
        const searchFields = {
          ...state.searchFields,
          [fieldName]: value
        };

        return { ...state, searchFields };
      });
    };
  };

  onChangePersonFieldsFactory = fieldName => {
    return event => {
      let value;
      if (fieldName === "fechaNacimiento") {
        value = event;
      } else {
        value = event.target.value;
      }
      this.setState(state => {
        const personFields = {
          ...state.personFields,
          [fieldName]: value
        };

        return { ...state, personFields };
      });
    };
  };

  searchClients = async e => {
    e.preventDefault();
    const args = { ...this.state.searchFields };

    this.setState({
      loading: true
    });

    try {
      if (this.state.personaID && args.tipo == PEOPLE_TYPES.EMPLEADO) {
        const {
          value: { data: personByID }
        } = await this.props.getPersonByNumeroEmpleado(this.state.personaID);

        this.setState({
          screenMode: SCREEN_MODES.SEARCHING,
          personByID,
          persons: null,
          errorMessage: true,
          loading: false
        });
      } else if (this.state.personaID) {
        const {
          value: { data: personByID }
        } = await this.props.searchClientByID(this.state.personaID);

        this.setState({
          screenMode: SCREEN_MODES.SEARCHING,
          personByID,
          persons: null,
          errorMessage: true,
          loading: false
        });
      } else {
        if (!!args.nombre && !!args.nombre.length) {
          args.nombre = args.nombre.trim();
        }
        if (!!args.apellido && !!args.apellido.length) {
          args.apellido = args.apellido.trim();
        }
        args.sexo = args.sexo || null;

        const {
          value: { data: persons }
        } = await this.props.searchClients(args);

        this.setState({
          screenMode: SCREEN_MODES.SEARCHING,
          persons,
          personByID: null,
          errorMessage: true,
          loading: false
        });
      }
    } catch (error) {
      this.setState({
        loading: false
      });
    }
  };

  newParter = e => {
    e.preventDefault();
    this.setState({ screenMode: SCREEN_MODES.CREATING });
  };

  onChangePersonaID = event => {
    const personaID = event.target.value;
    this.setState({ ...this.state, personaID });
  };

  onSubmit = e => {
    e.preventDefault();
  };

  selectPerson = id => {
    this.setState({ selectedPerson: id });
  };

  checkBlockMail = e => {
    const bloqueoMail = e.target.checked;
    this.setState(() => ({
      personFields: { ...this.state.personFields, bloqueoMail }
    }));
  };

  checkBlockCallCenter = e => {
    const bloqueoCallCenter = e.target.checked;
    this.setState(() => ({
      personFields: { ...this.state.personFields, bloqueoCallCenter }
    }));
  };

  getPersonsRender = () => {
    const personsToRender =
      this.state.persons ||
      (this.state.personByID ? [this.state.personByID] : null);
    if (
      !isNil(personsToRender) &&
      personsToRender.length == 0 &&
      this.state.errorMessage
    ) {
      this.setState({ errorMessage: false });
      errorNotification(NOT_PERSON_FINDED);
    }
    return personsToRender && personsToRender.length > 0 ? (
      <Table size="sm" hover className="table-borderless">
        <thead>
          <tr>
            <th>Persona Id</th>
            <th>Nombre</th>
            <th>A. Paterno</th>
            <th>A. Materno</th>
            <th>Número</th>
            <th>Correo</th>
            <th>F. Nacimiento</th>
            <th>Tipo</th>
          </tr>
        </thead>
        <tbody>
          {personsToRender && personsToRender.length > 0
            ? personsToRender.map(prospect => (
                <tr
                  className={s.personRow}
                  style={
                    prospect.personaId === this.state.selectedPerson
                      ? { backgroundColor: "rgba(0, 0, 0, 0.075)" }
                      : {}
                  }
                  onClick={() => this.selectPerson(prospect.personaId)}
                  key={prospect.personaId}
                >
                  <th scope="row">{prospect.personaId}</th>
                  <td>{prospect.nombre}</td>
                  <td>{prospect.primerApellido}</td>
                  <td>{prospect.segundoApellido || ""}</td>
                  <td>
                    {prospect.telefonos &&
                      prospect.telefonos.length &&
                      prospect.telefonos[0].numero}
                  </td>
                  <td>
                    {prospect.correosElectronicos &&
                      prospect.correosElectronicos.length &&
                      prospect.correosElectronicos[0].correo}
                  </td>
                  <td>{prospect.fechaNacimiento}</td>
                  <td>{prospect.tipo}</td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
    ) : null;
  };

  validPersonFieldsRequired = () => {
    let isValid = true;
    if (
      this.state.personFields.nombre == null ||
      this.state.personFields.nombre == ""
    ) {
      isValid = false;
    } else if (
      this.state.personFields.primerApellido == null ||
      this.state.personFields.primerApellido == ""
    ) {
      isValid = false;
    } else if (
      this.state.personFields.segundoApellido == null ||
      this.state.personFields.segundoApellido == ""
    ) {
      isValid = false;
    }
    return isValid;
  };

  validCreatingForm = () => {
    let isValid = true;
    Object.keys(this.state.personFields).forEach(key => {
      if (
        key === "tipoCliente" &&
        this.state.personFields[key] === PARTNER_TYPES.EMPLEADO
      ) {
        isValid = isValid && !!this.state.personFields.numeroEmpleado;
      } else {
        isValid =
          isValid &&
          (key === "numeroEmpleado" || !isNil(this.state.personFields[key]));
      }
    });

    return isValid;
  };

  linkPerson = e => {
    e.preventDefault();
    if (this.state.screenMode === SCREEN_MODES.SEARCHING) {
      if (
        this.state.personByID &&
        this.state.personByID.personaId === this.state.selectedPerson
      ) {
        this.props.setSelectedPerson(this.state.personByID);
      } else if (this.state.persons.length > 0) {
        const person = this.state.persons.filter(
          persona => persona.personaId === this.state.selectedPerson
        );
        this.props.setSelectedPerson(person[0]);
      }
    } else {
      this.props.createPerson({
        ...this.state.personFields,
        fechaNacimiento: moment(this.state.personFields.fechaNacimiento).format(
          "YYYY-MM-DD"
        )
      });
    }
  };

  selectPartnerType = e => {
    const tipoCliente = e.target.value;
    const tipo = e.target.value;
    this.setState(state => ({
      ...state,
      personFields: {
        ...state.personFields,
        tipoCliente,
        tipo,
        numeroEmpleado:
          tipoCliente !== PARTNER_TYPES.EMPLEADO
            ? null
            : state.personFields.numeroEmpleado
      }
    }));
  };

  render() {
    return (
      <div className={s.modalContainer}>
        <div className="h-100">
          {this.props.personLinkError &&
            this.props.personLinkError.map((message, index) => {
              return (
                <span key={index} className={s.errorSpan}>
                  {message}
                  <br />
                </span>
              );
            })}
          <form className={s.basicDataClub}>
            <div className="form-row">
              <div className="col">
                <BaseInput
                  label={
                    this.state.searchFields.tipo == PEOPLE_TYPES.EMPLEADO
                      ? "No. de Empleado"
                      : "Persona Id"
                  }
                  name="personaID"
                  type="text"
                  id="personaID"
                  placeholder={
                    this.state.searchFields.tipo == PEOPLE_TYPES.EMPLEADO
                      ? "No. de Empleado"
                      : "Persona Id"
                  }
                  value={this.state.personaID}
                  onChange={e => this.onChangePersonaID(e)}
                  disabled={
                    this.state.searchFields.apellido ||
                    this.state.searchFields.nombre
                  }
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"Nombre"}
                  name="nombre"
                  type="text"
                  id="nombre"
                  placeholder="Nombre"
                  value={this.state.searchFields.nombre}
                  disabled={this.state.personaID}
                  onChange={this.onChangeSearchFieldsFactory("nombre")}
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"Apellido"}
                  name="apellido"
                  type="text"
                  id="apellido"
                  placeholder="Apellido"
                  value={this.state.searchFields.apellido}
                  disabled={this.state.personaID}
                  onChange={this.onChangeSearchFieldsFactory("apellido")}
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"Sexo"}
                  name="sexo"
                  type="select"
                  id="sexo"
                  placeholder="Sexo"
                  value={this.state.searchFields.sexo}
                  disabled={this.state.personaID}
                  options={genreOptions}
                  onChange={this.onChangeSearchFieldsFactory("sexo")}
                />
              </div>
              <div className="col">
                <DatePicker
                  label="Edad"
                  name="edad"
                  id="edad"
                  selected={this.state.searchFields.edad}
                  onChange={this.onChangeSearchFieldsFactory("edad")}
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"Tipo de persona"}
                  name="tipo"
                  type="select"
                  id="tipo"
                  placeholder="Tipo de persona"
                  value={this.state.searchFields.tipo}
                  disabled={this.state.personaID}
                  options={typeOptions}
                  onChange={this.onChangeSearchFieldsFactory("tipo")}
                />
              </div>
              <div className="col-1">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                  onClick={e => this.searchClients(e)}
                  size="sm"
                  block
                  disabled={
                    (!this.state.personaID &&
                      !(
                        this.state.searchFields.apellido &&
                        this.state.searchFields.nombre
                      )) ||
                    this.state.loading
                  }
                >
                  {!this.state.loading ? "Buscar" : "Espere"}
                </Button>
              </div>
              <div className="col-1">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                  size="sm"
                  block
                  onClick={e => this.newParter(e)}
                  disabled={this.state.loading}
                >
                  {"Nuevo"}
                </Button>
              </div>
            </div>
          </form>
          <hr />
          {this.state.screenMode === SCREEN_MODES.SEARCHING ? (
            this.getPersonsRender()
          ) : this.state.screenMode === SCREEN_MODES.CREATING ? (
            <form className={s.basicDataClub}>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Nombre"}
                    name="nombre"
                    type="text"
                    id="nombre"
                    placeholder="Nombre"
                    value={this.state.personFields.nombre}
                    onChange={this.onChangePersonFieldsFactory("nombre")}
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Apellido Paterno"}
                    name="primerApellido"
                    type="text"
                    id="primerApellido"
                    placeholder="Apellido Paterno"
                    value={this.state.personFields.primerApellido}
                    onChange={this.onChangePersonFieldsFactory(
                      "primerApellido"
                    )}
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Apellido Materno"}
                    name="segundoApellido"
                    type="text"
                    id="segundoApellido"
                    placeholder="Apellido Materno"
                    value={this.state.personFields.segundoApellido}
                    onChange={this.onChangePersonFieldsFactory(
                      "segundoApellido"
                    )}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <DatePicker
                    label="Fecha de Nacimiento"
                    name="fechaNacimiento"
                    id="fechaNacimiento"
                    selected={this.state.personFields.fechaNacimiento}
                    onChange={this.onChangePersonFieldsFactory(
                      "fechaNacimiento"
                    )}
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Sexo"}
                    name="sexo"
                    type="select"
                    options={genreOptions}
                    id="sexo"
                    placeholder="Sexo"
                    value={this.state.personFields.sexo}
                    onChange={this.onChangePersonFieldsFactory("sexo")}
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Entidad Nacimiento"}
                    name="entidadNacimiento"
                    type="select"
                    options={entidadNacimientoOptions}
                    id="entidadNacimiento"
                    placeholder="Entidad Nacimiento"
                    value={this.state.personFields.entidadNacimiento}
                    onChange={this.onChangePersonFieldsFactory(
                      "entidadNacimiento"
                    )}
                  />
                </div>
              </div>
              <div className="form-row">
                {/* <div className="col p-0">
                  <BaseInput
                    label={"Numero de Empleado"}
                    name="numeroEmpleado"
                    type="number"
                    id="numeroEmpleado"
                    placeholder="Numero de Empleado"
                    disabled={
                      this.state.personFields.tipoCliente !==
                      PARTNER_TYPES.EMPLEADO
                    }
                    value={this.state.personFields.numeroEmpleado}
                    onChange={this.onChangePersonFieldsFactory(
                      "numeroEmpleado"
                    )}
                  />
                </div> */}
                <div className="col p-0">&nbsp;</div>
                <div className="col mt-4">
                  <div
                    className="row m-0"
                    style={{
                      position: "absolute",
                      bottom: "0.25rem",
                      left: "1.25rem"
                    }}
                  >
                    {/* <label className="px-1">
                      <input
                        type="radio"
                        id="optionEmpleadoPartnerType"
                        value={PARTNER_TYPES.EMPLEADO}
                        onChange={this.selectPartnerType}
                        checked={
                          this.state.personFields.tipoCliente ===
                          PARTNER_TYPES.EMPLEADO
                        }
                      />
                      {` ${PARTNER_TYPES.EMPLEADO}`}
                    </label> */}
                    <label className="px-1">
                      <input
                        type="radio"
                        id="optionEmpleadoPartnerType"
                        value={PARTNER_TYPES.SOCIO}
                        disabled={true}
                        onChange={this.selectPartnerType}
                        checked={
                          this.state.personFields.tipoCliente ===
                          PARTNER_TYPES.SOCIO
                        }
                      />
                      {` ${PARTNER_TYPES.SOCIO}`}
                    </label>
                    <label className="px-1">
                      <input
                        type="radio"
                        name="optionExternoPartnerType"
                        id="optionExternoPartnerType"
                        value={PARTNER_TYPES.EXTERNO}
                        onChange={this.selectPartnerType}
                        checked={
                          this.state.personFields.tipoCliente ===
                          PARTNER_TYPES.EXTERNO
                        }
                      />
                      {` ${PARTNER_TYPES.EXTERNO}`}
                    </label>
                    <label className="px-1">
                      <input
                        type="radio"
                        name="optionCorporativoPartnerType"
                        id="optionCorporativoPartnerType"
                        value={PARTNER_TYPES.CORPORATIVO}
                        onChange={this.selectPartnerType}
                        checked={
                          this.state.personFields.tipoCliente ===
                          PARTNER_TYPES.CORPORATIVO
                        }
                      />
                      {` ${PARTNER_TYPES.CORPORATIVO}`}
                    </label>
                  </div>
                </div>
                <div className="col p-0">
                  <div>
                    <FormGroup
                      check
                      className="form-check-inline float-right pl-1"
                    >
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.personFields.bloqueoMail}
                          onChange={e => this.checkBlockMail(e)}
                        />{" "}
                        Bloqueo Mail
                      </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-inline float-right">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.personFields.bloqueoCallCenter}
                          onChange={e => this.checkBlockCallCenter(e)}
                        />{" "}
                        Bloqueo Call Center
                      </Label>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </form>
          ) : null}
        </div>
        <form>
          <div className="form-row p-0 m-0">
            <div className="col" />
            <div className="col-1 float-right pr-0">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                onClick={e => this.linkPerson(e)}
                size="sm"
                block
                disabled={
                  (this.state.screenMode === SCREEN_MODES.SEARCHING &&
                    !this.state.selectedPerson) ||
                  (this.state.screenMode === SCREEN_MODES.CREATING &&
                    (!this.validCreatingForm() ||
                      !this.validPersonFieldsRequired()))
                }
              >
                {this.state.screenMode === SCREEN_MODES.SEARCHING
                  ? "Asociar"
                  : "Guardar"}
              </Button>
            </div>
            {/* <div className="col-4 float-right pr-0">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                onClick={e => this.linkPartner(e)}
                size="sm"
                block
                disabled={
                  (this.state.screenMode === SCREEN_MODES.SEARCHING &&
                    !this.state.selectedPartner) ||
                  (this.state.screenMode === SCREEN_MODES.CREATING &&
                    !this.validCreatingForm())
                }
              >
                {this.state.screenMode === SCREEN_MODES.SEARCHING
                  ? "Asociar sin validación de rango de edad"
                  : "Guardar"}
              </Button>
            </div> */}
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(withStyles(s)(AddPerson));
