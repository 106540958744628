import React from "react";
import ModalFrame from "../../../toolkit/modalFrame";
import LinkPartner from "../../components/linkPartner";
import { connect } from "react-redux";
import {
  getPersons,
  getPersonById,
  getPersonByNumeroEmpleado
} from "../../actions/persons";
import { linkPartner, createPartner } from "../../actions/partners";

interface Props {
  isOpen: boolean;
  linkPartnerTitle: string;
  isFreePartner: boolean;
  isChangeFreePartner: boolean;
  changeFreePartnerData: any;
  isFreeChildPartner: boolean;
  persons: object[];
  personByID: object;
  partnerTypes: object[];
  partnerCreated: boolean;
  partnerLinkError: any;
  partnerLinking: boolean;
  membershipTypesMembers: any[];
  toggle: () => any;
  searchPersons: (args: object) => any;
  getpersonByID: (id: string) => any;
  getPartnerTypes: () => any;
  linkPartner: (
    idMembresiaSocio: string,
    partnerType: string,
    id: string,
    isFreePartner: boolean,
    isChangeFreePartner: boolean,
    changeFreePartnerData: any
  ) => any;
  createPartner: (
    idMembresiaSocio: string,
    partnerType: string,
    partner: object,
    isFreePartner: boolean,
    isChangeFreePartner: boolean,
    changeFreePartnerData: any
  ) => any;
}

class LinkPartnerModal extends React.Component<Props> {
  componentDidUpdate({ partnerCreated: prevPartnerCreated }) {
    if (!prevPartnerCreated && this.props.partnerCreated) {
      this.props.toggle();
    }
  }

  render() {
    return (
      <ModalFrame
        title={this.props.linkPartnerTitle || "Nuevo Socio"}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size="lg"
      >
        {this.props.membershipTypesMembers && (
          <LinkPartner
            isFreePartner={this.props.isFreePartner}
            isChangeFreePartner={this.props.isChangeFreePartner}
            changeFreePartnerData={this.props.changeFreePartnerData}
            isFreeChildPartner={this.props.isFreeChildPartner}
            persons={this.props.persons}
            searchClients={this.props.searchPersons}
            searchClientByID={this.props.getpersonByID}
            personByID={this.props.personByID}
            linkPartner={this.props.linkPartner}
            createPartner={this.props.createPartner}
            partnerLinkError={this.props.partnerLinkError}
            partnerLinking={this.props.partnerLinking}
            membershipTypesMembers={this.props.membershipTypesMembers}
            getPersonByNumeroEmpleado={getPersonByNumeroEmpleado}
          />
        )}
      </ModalFrame>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  searchPersons: (args: object) => {
    dispatch(getPersons(args));
  },
  getpersonByID: (id: string) => {
    dispatch(getPersonById(id));
  },
  getPersonByNumeroEmpleado: (id: string) => {
    dispatch(getPersonByNumeroEmpleado(id));
  },
  linkPartner: (
    idMembresiaSocio: string,
    partnerType: string,
    id: string,
    isFreePartner: boolean,
    isFreeChildPartner: boolean,
    isChangeFreePartner: boolean,
    changeFreePartnerData: any
  ) => {
    dispatch(
      linkPartner(
        idMembresiaSocio,
        partnerType,
        id,
        isFreePartner,
        isFreeChildPartner,
        isChangeFreePartner,
        changeFreePartnerData
      )
    );
  },
  createPartner: (
    idMembresiaSocio: string,
    partnerType: string,
    partner: object,
    isFreePartner: boolean,
    isFreeChildPartner: boolean,
    isChangeFreePartner: boolean,
    changeFreePartnerData: any
  ) => {
    dispatch(
      createPartner(
        idMembresiaSocio,
        partnerType,
        partner,
        isFreePartner,
        isFreeChildPartner,
        isChangeFreePartner,
        changeFreePartnerData
      )
    );
  }
});

const mapStateToProps = state => {
  const { persons, partners, products } = state;

  return {
    persons: persons.persons,
    personByID: persons.personById,
    partnerTypes: partners.partnerTypes,
    partnerCreated: partners.partnerCreated,
    partnerLinkError: partners.partnerLinkError,
    partnerLinking: partners.partnerLinking,
    membershipTypesMembers: products.membershipTypesMembers
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkPartnerModal);
